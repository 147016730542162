<template>
  <v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" :top="top" :centered="centered" :left="left" :right="right"
      :bottom="bottom" :multi-line="multiline">
      {{ message }}
      <v-btn color="blue" text @click="snackbar = false">
        {{ $t("Globals.btn_close") }}
      </v-btn>
    </v-snackbar>
    <v-row justify="center">
      <v-col>

        <v-dialog v-model="dialog" :persistent="persistent" min-width="400" max-width="500">
          <v-card max-width="auto">
            <v-card-title class="headline">
            </v-card-title>
            <v-card-text style="white-space: normal ;">
              <p>{{ message === 'debtors alert updated status invoice in bexio and database qr' ? null : message }}
              </p>


              <v-list v-if="optionalMessage.alert_body">
                <v-list-group no-action sub-group v-for="(body, i) in optionalMessage.alert_body" :key="i">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>{{ body.msj }}</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item link>
                    <v-list-item-icon>
                      <v-icon
                        :color="body.icon_type == 'mdi-checkbox-marked-circle' ? 'green' : body.icon_type == 'mdi-alert' ? 'yellow' : 'red'"
                        v-text="body.icon_type"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title v-text="body.status"></v-list-item-title>
                  </v-list-item>
                </v-list-group>
              </v-list>
              <p v-else-if="optionalMessage">{{ optionalMessage }} </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog = false">
                {{ $t("qrGenerator.windowsCredit.btn_agree") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>

    </v-row>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      message: '',
      optionalMessage: '',
      timeout: 5000,
      left: false,
      right: false,
      bottom: false,
      centered: false,
      top: false,

      multiline: false,
      persistent: false,

      style: {
        message: {}
      }
    }
  },
  computed: {
    ...mapGetters({
      snackbarOptions: 'global/snackbar',
      snackbarStatus: 'global/snackbarStatus',
      dialogStatus: 'global/dialogStatus'
    }),

    dialog: {
      get() {
        return this.dialogStatus
      },
      set(value) {
        this.$store.state.global.dialogStatus = value
      }
    },
    snackbar: {
      get() {
        return this.snackbarStatus;
      },
      set(value) {
        this.$store.state.global.snackbarStatus = value;
      }
    }
  },
  created() {

    this.$store.watch(
      state => state.global.snackbar.message,
      (value) => {
        if (!value) return;  // Si el valor es vacío, no hacer nada


        console.log('data = ', value)
        console.log('length = ', value.length)
        console.log('this.snackbarOptions.type', this.snackbarOptions.type)
        // Asignar el tipo de notificación según las opciones
        if (this.snackbarOptions.type === 'snackbar') {
          this.snackbar = true;
        } else if (this.snackbarOptions.type === 'dialog' || value.length > 35) {
          this.dialog = true;
        } else {
          this.snackbar = true;
        }

        // Asignar las opciones de snackbar al componente
        Object.assign(this, this.snackbarOptions);

        // Limpiar el mensaje del snackbar en Vuex
        this.$store.commit('global/setSnackbar', { message: '' });
      }
    );


    this.$store.watch(state => state.global.snackbarStatus, (value, old) => {
      if (!value) {
        this.resetSnackbar();
      }
    })

    this.$store.watch(state => state.global.dialogStatus, (value, old) => {
      if (!value) {
        this.resetSnackbar();
      }
    })
  },
  methods: {
    resetSnackbar() {
      this.snackbarOptions.left = false;
      this.snackbarOptions.right = false;
      this.snackbarOptions.bottom = false;
      this.snackbarOptions.centered = false;
      this.snackbarOptions.top = false;
      this.snackbarOptions.multiline = false;
      this.snackbarOptions.persistent = false;
      this.snackbarOptions.type = '';
    }
  }
}
</script>